import { storyblokEditable } from '@storyblok/react/rsc'
import Image from 'next/image'
import { type FC } from 'react'

import { Link } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAdviceCardType } from '../../model/storyblokTypes.generated'

export const StoryblokAdviceCard: FC<
  StoryblokComponentProps<StoryblokAdviceCardType>
> = ({ blok }) => {
  const { image, link, title } = blok

  if (!link.url) {
    return null
  }

  return (
    <section
      className="relative flex flex-col gap-2"
      {...storyblokEditable(blok)}
    >
      {image ? (
        <Image
          alt=""
          className="w-full rounded-lg desktop:rounded-2xl"
          height={64}
          src={image}
          unoptimized
          width={148}
        />
      ) : null}
      {title ? (
        <h3 className="text-s font-medium">
          <Link color="inherit" href={link.url} overlay>
            {title}
          </Link>
        </h3>
      ) : null}
    </section>
  )
}
